// This file is  for the footer content. 
// Most likely some information sobreme or git repo like matt has or something idk.
// little arrow thing too
import './footer.css';
import React, { useEffect } from 'react';

function Footer() {
  useEffect(() => {
    changeArrow();

    return () => {
    };
  }, []);

  function changeArrow() {
    // Changing the bottom nav.
    var arrow = document.getElementById("arrow");
    resetArrow(arrow);
  }

  function resetArrow(element) {
    element.className = "arrowDown";
    element.setAttribute("href", "#top");
  }

  function arrowOnClick(element) {
    if (element.className === "arrowUp") {
      element.className = "arrowDown";
      element.setAttribute("href", "#top");
    } else {
      element.className = "arrowUp";
      element.setAttribute("href", "#content");
    }
  }

  return (
    <footer>
      <a id="arrow" class="arrowDown" href="#content" onClick={() => arrowOnClick(document.getElementById("arrow"))}></a>
    </footer>
  );
}

export default Footer;
