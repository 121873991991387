// This file will contain the main content of the page. On the current webpage this is the name along with photo showcase and intro 'paragraph'.
import './mainContent.css';
import React, { useState, useEffect } from 'react';


function MainContent() {
  const imageFilePaths = ['./res/000000070010-EDIT.jpg', './res/000000070012-EDIT.jpg', './res/000000070032-EDIT.jpg', './res/000000070033-EDIT.jpg', './res/000000070034-EDIT.jpg', './res/0002_22A-EDIT.jpg', './res/0003_21A-EDIT3.png', './res/0004_19A-EDIT.jpg', './res/0004_20A-EDIT.jpg', './res/0011_13A-EDIT.jpg', './res/0013_10A-EDIT.jpg', './res/0019_4A-EDIT.jpg', './res/45780011-EDIT.jpg', './res/45780015-EDIT.jpg', './res/45780017-EDIT.jpg', './res/45780019-EDIT.jpg', 
  './res/45780020-EDIT.jpg', './res/45780021-EDIT.jpg', './res/45780032-EDIT.jpg', './res/45780033-EDIT.jpg', './res/45780034-EDIT.jpg', './res/IMG_20230512_153928_563-EDIT.jpg', './res/R1-09599-0001-EDIT.jpg', './res/R1-09599-0002-EDIT.jpg', './res/R1-09599-0003-EDIT.jpg', './res/R1-09599-0004-EDIT.jpg', './res/R1-09599-0007-EDIT.jpg', './res/R1-09599-0008-EDIT.jpg', './res/R1-09599-0010-EDIT.jpg', './res/R1-09599-0012-EDIT.jpg', './res/R1-09599-0017-EDIT.jpg', './res/R1-09599-0022-EDIT.jpg', './res/R1-09599-0024-EDIT.jpg', './res/R1-09599-0025-EDIT.jpg', './res/R1-09599-0028-EDIT.jpg'];
  
  useEffect(() => {
   
    // Function to change the photo
function randomPhoto(photo) {
  const randomIndex = Math.floor(Math.random() * imageFilePaths.length);
  photo.src = imageFilePaths[randomIndex];
  }

// Changing the photo on click
var mainPhoto = document.getElementById("mainPhoto");
mainPhoto.addEventListener("click", function(){
  randomPhoto(mainPhoto);});

// Changing the intro text.
var intro = document.getElementById("intro");
var currentIndex = 0;
introOnClick(intro);
intro.addEventListener("click", function() {
  introOnClick(intro);
});

function introOnClick(element) {
  currentIndex = currentIndex + 1;
  if (currentIndex > 2){
    currentIndex = 0;
  }
  var sayingsArray = ["cars, music, horrible photography.", "horrible photography, cars, music.", "cars, horrible photography, music."];
  element.textContent = sayingsArray[currentIndex];
}
    return () => {};
  }, []);
    return (
      <div className="MainContent">
        <div className="headerContainer">
          <div className="headerItem item1">
            <h1 className="name">
              <a href="https://www.linkedin.com/in/orlando-o/" target="_blank"
                >orlando</a
              >
            </h1>
            <p className="intro" id="intro">
              <a id="horriblePhotography">horrible photography</a>,
              <a className="introElement">cars</a>, <a className="introElement">music</a>.
            </p>
          </div>
          <div className="headerItem item2">
            <img id="mainPhoto" src="./res/0003_21A-EDIT3.png" alt="" />
          </div>
      </div>
    </div>
    );
  }
  
  export default MainContent;