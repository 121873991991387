import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Header from './Header';
import MainContent from './MainContent';
import SecondaryContent from './SecondaryContent';
import Footer from './Footer';


const root = ReactDOM.createRoot(document.getElementById('root'));
window.onload = function() {
  window.scrollTo(0, 0);
};
root.render(
  <React.StrictMode>
    {/* <Header/> */}
    <MainContent/>
    <SecondaryContent/>
    <Footer/>
  </React.StrictMode>
);

