import './secondaryContent.css';
import React, { useEffect } from 'react';

function SecondaryContent() {
    useEffect(() => {
        contentSlider();

        return () => {
        };
    }, []);

    return (
        <div class="contentContainer" id="content">
      <a id="leftArrow" class="contentArrow arrowLeft"></a>
      <div class="contentItem item3">
        <h2 class="aboutMe questionHeader">About Me:</h2>
        <p class="aboutMe content">
          Computer Information Systems student at Mount Royal University,
          amateur photographer, travel lover, music enjoyer, car driver.
        </p>
      </div>
      <div class="contentItem item4">
        <h2 class="lifeGoal questionHeader">Life Goal:</h2>
        <p class="lifeGoal content">Learn 5 languages.</p>
      </div>
      <div class="contentItem item5">
        <h2 class="whatAnimal questionHeader">Favourite Animal:</h2>
        <p class="whatAnimal content">
          Love panthers, but there's just something about giraffes.
        </p>
      </div>
      <div class="contentItem item6">
        <h2 class="whatMusic questionHeader">Musical Preferences:</h2>
        <p class="whatMusic content">anything. just not country.</p>
      </div>
      <div class="contentItem item7">
        <h2 class="howContact questionHeader">Contact:</h2>
        <p class="howContact content">
          <a class="contactInfo" id="email">email. </a>
          <a class="contactInfo" id="linkedin">linkedin. </a>
          <a class="contactInfo" id="instagram">instagram. </a>
        </p>
      </div>
      <a class="contentArrow arrowRight"></a>
    </div>
     );
    }
    
    export default SecondaryContent;


    function contentSlider() {
        // Content slider
        var content = document.getElementsByClassName("contentItem");
        var contentIndex = 0;
        var arrows = document.getElementsByClassName("contentArrow");
        arrows[0].addEventListener("click", function() {
        changeShowCase(content, "left");
        });
        arrows[1].addEventListener("click", function() {
            changeShowCase(content, "right");
        });
    
        function changeShowCase(content, arrow){
        if (arrow == "right"){ 
            content[contentIndex].style.display = 'none';
            if (contentIndex >= content.length - 1){
            contentIndex = -1;
            }
            contentIndex = contentIndex + 1;
            content[contentIndex].style.display = "block";
        } else if (arrow == "left") {
            content[contentIndex].style.display = 'none';
            if (contentIndex == 0){
            contentIndex = content.length;
            }
            contentIndex = contentIndex - 1;
            content[contentIndex].style.display = "block";
        }
        }
    }